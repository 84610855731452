@import url('https://fonts.googleapis.com/css2?family=Sometype+Mono:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #3f7cdb;
}
.current-value {
  display: none !important;
}
.segment-value {
  font-weight: 100 !important;
}
text:first-child {
  transform: rotate(0deg) translate(-39%, 6%) !important;
}
text:last-child {
  transform: rotate(0deg) translate(38%, 6%) !important;
}
html {
  font-family: 'Atakk', sans-serif;
  scroll-behavior: smooth;
}

@layer base {
  @font-face {
    font-family: 'Atakk';
    src: url('./assets/fonts/Atakk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Atakk';
    src: url('./assets/fonts/Atakk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Atakk';
    src: url('./assets/fonts/Atakk-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Atakk';
    src: url('./assets/fonts/Atakk-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
}

@layer components {
  input,
  select {
    @apply bg-white80 outline outline-1 outline-grey60 py-[10px] px-4 h-12 w-full rounded placeholder:text-grey20;
  }

  input[data-bg='white'] {
    @apply bg-white outline outline-1 outline-grey60 py-[10px] px-4 h-12 w-full rounded placeholder:text-grey20;
  }

  select[data-smallerselect] {
    @apply bg-white80 outline outline-1 outline-grey60 pl-2 pr-7 py-0 pt-1 h-[28px] w-32 rounded placeholder:text-grey20 text-grey font-medium text-xs;
  }

  textarea {
    @apply w-full h-32 px-4 pt-3 rounded bg-white80 outline outline-1 outline-grey60 placeholder:text-grey20 text-sm;
  }

  textarea[data-smallertextarea] {
    @apply w-full px-2 pt-2 rounded h-22 bg-white80 outline outline-1 outline-grey60 placeholder:text-grey20;
  }

  input[type='checkbox'] {
    @apply cursor-pointer outline;
  }

  label {
    @apply flex flex-col items-start max-w-full text-sm font-medium text-body;
  }

  label[data-smallerlabel] {
    @apply flex flex-col items-start max-w-full text-xs font-medium text-body;
  }

  input[type='search']:focus-visible,
  input:focus-visible,
  input:focus-within,
  select:focus-visible,
  textarea:focus-visible {
    @apply outline outline-1 outline-brandBlue;
  }

  input[type='search'] {
    @apply border border-white80 pl-12 pt-[15px] pb-[11px] text-base h-[50px];
  }



  input[type='radio'],
  input[type='checkbox'] {
    @apply outline-none accent-brandBlue;
  }

  input[type='date'] {
    position: relative;
    height: 48px;
    width: 100%;
  }

  /*noinspection CssOverwrittenProperties*/
  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    transform: scaleX(-1);
    cursor: pointer;
    right: 16px;
    padding: 16px;
    margin-right: -16px;
    padding-left: 16px;
    width: 100%;
  }

  button {
    @apply disabled:bg-white80 disabled:text-grey30 disabled:hover:bg-white80 disabled:hover:text-grey30 disabled:cursor-not-allowed;
  }

  .card-link {
    @apply transition-all duration-200 border border-transparent hover:border-brandBlue60 active:border-brandBlue40;
  }

  .tooltip {
    @apply absolute invisible transition-opacity opacity-0;
  }

  .has-tooltip:hover .tooltip {
    @apply z-50 visible transition duration-300 ease-in-out opacity-100;
  }

  iframe#webpack-dev-server-client-overlay  {
    display: none !important;
  }

  .tooltip::before {
    content: '';
    position: absolute;
    top: -14px;
    border-width: 8px;
    border-style: solid;
    border-color: #444771 transparent transparent transparent;
    transform: rotate(180deg);
  }

  .widget-form input,
  .widget-form textarea,
  .widget-form select {
    @apply outline outline-1 -outline-offset-1 outline-grey60 focus-within:outline-brandBlue focus-visible:outline-brandBlue;
  }

  .widget-form input[type='radio'] {
    @apply h-auto rounded-full accent-black outline-none outline-0 focus-visible:outline focus-visible:outline-1 focus-visible:outline-brandBlue w-auto;
  }

  .google-translate-dropdown {
    @apply bg-white text-brandBlue border border-brandBlue rounded shadow-md p-4 text-sm;
  }
  .google-translate-dropdown .goog-te-gadget-simple {
    border: none !important;
    display: flex !important;
  }
  .google-translate-dropdown .goog-te-gadget-simple span a {
    display: flex !important;
  }

  .logs-tr .logs-code span {
    /* display: none; */
    transition: all 0.5s ease-in-out;
    transform: translateX(-3px);
    visibility: hidden;
    opacity: 0;
    width: 2px;
  }

  .logs-tr:hover .logs-code span {
    /* display: block; */
    visibility: visible;
    transform: translateX(3px);
    transition: 0.3s ease-in-out;
    width: 100%;
    opacity: 1;
  }

  select {
    /* for Firefox */
    -moz-appearance: none;
    appearance: none;
    /* for Safari, Chrome, Opera */
    -webkit-appearance: none;
    font-size: 14px;
    color: #080917;
    background: url('./assets/images/dropdown.svg') no-repeat right #f9f9f9;
    background-position-x: calc(100% - 16px);
  }

  select[data-smallerselect] {
    background-position-x: calc(100% - 8px);
  }

  /* for IE */
  select::-ms-expand {
    display: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: none;
  }

  aside .active {
    background-color: #1c1d31;
  }

  @media screen and (max-width: 1024px) {
    aside .active {
      background-color: #d9e5f8;
    }

    aside a:hover {
      background-color: #d9e5f8;
    }
  }

  @media screen and (min-width: 1024px) {
    aside a:hover {
      background-color: #1c1d31;
    }
  }

  aside a {
    white-space: nowrap;
  }

  aside svg {
    width: 18px;
    height: 18px;
  }

  .logo {
    display: none;
  }

  /* activity log styling */
  .activity-parent {
    grid-template-columns: 0.8fr 4fr;
  }

  .activity-description:before {
    transform: translate(-50%, -50%);
  }
}

/* additional styling */

aside::-webkit-scrollbar,
.custom-flow::-webkit-scrollbar,
.lookup-modal::-webkit-scrollbar,
.userApp::-webkit-scrollbar {
  position: relative;
  right: 40px;
  width: 5px !important;
}

aside::-webkit-scrollbar-track,
.custom-flow::-webkit-scrollbar-track,
.lookup-modal::-webkit-scrollbar-track,
.userApp::-webkit-scrollbar-track {
  background: none !important;
}

aside::-webkit-scrollbar-thumb,
.custom-flow::-webkit-scrollbar-thumb,
.lookup-modal::-webkit-scrollbar-thumb,
.userApp::-webkit-scrollbar-thumb {
  background-color: #ceced2 !important;
  border-radius: 10px !important;
}

.widget-footer {
  background-image: url('../src/assets/images/widget-vector.svg');
}

[type='file'] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

[type='file'] + label {
  color: #3f7cdb;
  font-weight: 400;
}

[type='file']:focus + label,
[type='file'] + label:hover {
  cursor: pointer;
}

[type='file']:focus-visible + label {
  outline: 2px solid #000;
  border-radius: 2px;
}

/* custom react select styling */

.react-select__input-container,
.react-select-new-page__input-container {
  padding: 0 !important;
  margin: 0 !important;
}

.react-select__input {
  height: auto !important;
}

.react-select__placeholder,
.react-select-new-page__placeholder {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #8e90a9 !important;
}

.react-select__value-container,
.react-select-new-page__value-container {
  gap: 8px;
  padding: 0 !important;
}

.react-select__value-container--is-multi {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.react-select__option,
.react-select-new-page__option,
.react-select__single-value,
.react-select-new-page__single-value {
  font-size: 14px !important;
  color: #4a4a68 !important;
}

.react-select__option--is-focused,
.react-select-new-page__option--is-focused,
.react-select__option:hover .react-select-new-page__option:hover {
  background-color: #f1f1f1 !important;
}

.react-select__option--is-selected,
.react-select-new-page__option--is-selected {
  background-color: #333442 !important;
  color: #fff !important;
}

.apps-multi-select .react-select__option--is-selected,
.apps-multi-select .react-select-new-page__option--is-selected {
  background-color: var(--color-primary) !important;
}

.apps-multi-select .react-select__control {
  min-height: 48px !important;
}

.creatable-multi-select .react-select__control--is-focused:active,
.creatable-multi-select .react-select__control--is-focused:focus-within {
  border-width: 0 !important;
}

.apps-multi-select .react-select__option--is-selected:hover,
.apps-multi-select .react-select-new-page__option--is-selected:hover {
  background-color: var(--color-primary) !important;
}

.apps-multi-select .react-select__option {
  color: #080917 !important;
  font-size: 13px !important;
}

.apps-multi-select .react-select__option--is-selected {
  color: #fff !important;
}

.apps-multi-select .react-select__menu-list,
.apps-multi-select .react-select__menu-list--is-multi {
  padding: 8px !important;
}

.react-select__option--is-selected:hover,
.react-select-new-page__option--is-selected:hover {
  background-color: #333442 !important;
  color: #fff !important;
}

.react-select__multi-value {
  margin: 0 !important;
}

.react-select__clear-indicator,
.react-select-new-page__clear-indicator {
  display: none !important;
}

.react-select__control,
.react-select-new-page__control {
  padding-left: 16px !important;
  outline: 1px !important;
}

.react-select__control:hover,
.react-select-new-page__control:hover {
  outline-color: #ceced2 !important;
}

.react-select__control--is-focused,
.react-select-new-page__control--is-focused {
  outline-color: var(--color-primary) !important;
  outline-width: 1px !important;
}

.react-select__indicator-separator,
.react-select-new-page__indicator-separator {
  display: none !important;
}

.react-select__indicator,
.react-select-new-page__indicator {
  padding: 15px !important;
  padding-right: 16px !important;
}

.react-react-select__menu > p {
  text-align: left !important;
}
.react-select__menu {
  z-index: 20 !important;
}

.white-select > div {
  background-color: #fff !important;
  text-align: left !important;
}

/* multiselect */
.react-select__multi-value {
  background-color: var(--color-primary) !important;
  color: #d9e5f8;
  border-radius: 25px !important;
  flex-direction: row-reverse;
  gap: 4px;
  align-items: center !important;
  padding: 3px 8px !important;
  margin: 0 !important;
}

.react-select__multi-value__label {
  color: inherit !important;
  text-transform: uppercase;
  font-size: 10px !important;
  font-weight: 500;
  line-height: 12px;
  padding: 0 !important;
  position: relative;
  top: 3px;
  margin-bottom: 3px;
}

.react-select__multi-value__remove {
  padding: 0 !important;
}

.react-select__multi-value__remove:hover {
  background-color: transparent !important;
}

.react-select__control--is-focused:active,
.react-select__control--is-focused:focus-within {
  outline-color: var(--color-primary) !important;
  outline-width: 1px !important;
  border-color: var(--color-primary) !important;
}

.react-select-new-page__menu-list {
  height: auto;
}

.react-select__group-heading span:first-child {
  font-size: 10px !important;
  font-weight: 500 !important;
  color: #8e90a9 !important;
}

.creatable-multi-select .react-select__control {
  background-color: #fff !important;
  min-height: 48px !important;
}

.receive_webhook .creatable-multi-select .react-select__control {
  background-color: #f9f9f9 !important;
}

.creatable-multi-select .react-select__input {
  font-size: 13px !important;
}

.creatable-multi-select .react-select__placeholder {
  margin-bottom: -4px;
}

.creditcheck-select .react-select__control {
  width: fit-content !important;
  margin-left: auto !important;
}

.creditcheck-select .react-select__menu {
  width: 70% !important;
  right: 0 !important;
}

.globalsearch .react-select__menu-list {
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
}

.globalsearch .react-select__option {
  padding: 8px !important;
}

/* custom phone input styling */

.phone-no-container {
  outline: 1px solid #ceced2 !important;
  border-radius: 4px !important;
}

.phone-no-container:active,
.phone-no-container:focus-within {
  outline: 1px solid #3f7cdb !important;
}

.phone-no-input {
  height: 48px !important;
  width: 100% !important;
  background-color: #f9f9f9 !important;
  font-size: 13px !important;
  border-width: 0 !important;
  padding-left: 64px !important;
  font-family: 'Atakk', serif !important;
}

.phone-no-input:active,
.phone-no-input:focus-within {
  border-radius: 4px !important;
  border: none !important;
}

.flag-dropdown {
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  background-color: #f9f9f9 !important;
}

.selected-flag {
  padding-left: 16px !important;
  width: 56px !important;
}

.flag {
  margin-top: -6px !important;
}

.react-tel-input {
  max-height: 48px !important;
  display: flex !important;
  align-items: center !important;
}

.react-tel-input .selected-flag .arrow {
  left: 24px !important;
}

.react-tel-input .country-list {
  text-align: start !important;
}

.react-tel-input .country-list .country {
  font-size: 13px !important;
  color: #4a4a68 !important;
  font-family: 'Atakk', serif;
  font-weight: normal;
  padding: 8px 12px !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: #333442 !important;
  color: #fff !important;
}

.react-tel-input .country-list .country.highlight .dial-code {
  color: #fff !important;
}

.react-tel-input .country-list .search {
  padding: 10px !important;
}

.react-tel-input .country-list .search-box {
  padding: 8px !important;
}

.react-tel-input .country-list .no-entries-message {
  font-family: Atakk, sans-serif !important;
  font-size: 13px !important;
}

.react-tel-input .form-control {
  outline: 1px solid #cacaca !important;
  border-radius: 3px !important;
}

.react-tel-input .form-control:focus {
  outline: 1px solid #3f7cdb !important;
}

/* react toastify styles */

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  :root {
    --toastify-toast-min-height: 48px !important;
  }
}

.Toastify__toast-body {
  font-family: Atakk, sans-serif !important;
  font-weight: 500;
}

.Toastify__toast {
  border-radius: 4px !important;
}

.Toastify__toast--success {
  background-color: var(--color-primary) !important;
  font-weight: 700 !important;
  max-height: 20px !important;
  font-size: 0.8rem;
  color: white !important;
}

.Toastify__toast--error {
  background-color: #bb371a !important;
  font-weight: 700 !important;
  font-size: 0.8rem;
  color: white !important;
}

.Toastify__progress-bar {
  visibility: hidden;
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__close-button svg {
  fill: white !important;
}

.Toastify__toast-theme--light {
  max-width: 100% !important;
}

/* datepicker */

.lookup-filter .react-datepicker__input-container > input {
  font-size: 10px !important;
  font-weight: 500 !important;
  outline: none !important;
  height: 32px !important;
  cursor: pointer !important;
}

.react-datepicker {
  font-family: Atakk, sans-serif !important;
}

.react-datepicker__year-option .react-datepicker__navigation--years-previous {
  background: url(./assets/images/dropdown-nofill.svg) no-repeat;
  border: none;
  inset: 0 !important;
  width: 16px;
  height: 16px;
}

.react-datepicker__year-option .react-datepicker__navigation--years-upcoming {
  background: url(./assets/images/dropdown-nofill.svg) no-repeat;
  border: none;
  transform: rotate(180deg);
  inset: 0 !important;
  width: 16px;
  height: 16px;
}

.lookup-image {
  background-position: center !important;
  background-size: cover !important;
  border-radius: 50%;
  background-repeat: no-repeat !important;
  display: inline-block;
  height: 80px;
  width: 80px;
  object-fit: contain;
}

.page-limit {
  display: flex;
  align-items: center;
  color: #555;
  font-size: 0.875rem;
}

.page-limit button {
  margin: 0 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  position: relative;
}

.page-limit .dropdown {
  position: absolute;
  width: 100%;
  color: #555;
  background: white;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  top: 100%;
  right: 0;
  box-shadow: 0 5px 10px rgba(33, 33, 33, 0.12);
}

.page-limit .dropdown span {
  padding: 5px;
}

.page-limit .dropdown span:hover {
  background: #e3f5fa;
}

@keyframes skeleton-animation {
  0% {
    right: 100%;
  }

  50% {
    right: -128px;
  }

  100% {
    right: 100%;
  }
}

.skeleton {
  overflow: hidden;
  width: 328px;
  height: auto;
  display: grid;
  grid-template-columns: 128px 200px;
  grid-gap: 8px;
  position: relative;
  margin-bottom: 16px;
  /* margin-left: 7rem; */
  background: #eee;
}

.skeleton:before {
  display: block;
  position: absolute;
  width: 128px;
  height: 78px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    #fff,
    rgba(255, 255, 255, 0)
  );
  content: '';
  animation: skeleton-animation 1.25s infinite;
}

.skeleton .s-img {
  width: 128px;
  height: 78px;
  background: #eee;
  grid-row: 1 / 4;
}

.skeleton .s-line {
  height: 10px;
  background: #eee;
}

.skeleton .s-line.first {
  width: 100%;
}

.skeleton .s-line.second {
  width: 75%;
}

.skeleton .s-line.third {
  width: 50%;
}

.widget-radios {
  display: none;
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.widget-radios[type='checkbox'] + label {
  color: #677189;
  padding: 14px 32px !important;
  background-color: #f6f6f8;
  border: 1px solid #f6f6f8;
  border-radius: 40px;
  cursor: pointer;
}

.widget-radios[type='checkbox'] + label {
  padding: 14px 24px;
}

.widget-radios[type='checkbox']:checked + label {
  color: #1b2a4e !important;
  padding: 14px 32px !important;
  background-color: lightgray;
  border: 1px solid #3977de;
  border-radius: 40px;
  cursor: pointer;
}

/* apexcharts  */
.apexcharts-tooltip {
  background: #fff !important;
  color: #1b2a4e !important;
  box-shadow: 0 8px 8px rgba(50, 50, 71, 0.08),
    0 8px 16px rgba(50, 50, 71, 0.06) !important;
  border: 1px solid #fff !important;
}

.apexcharts-legend {
  gap: 16px !important;
  padding: 0 32px !important;
}

.apexcharts-legend-series {
  display: flex !important;
  align-items: flex-end !important;
  gap: 4px;
  margin: 0 !important;
}

.apexcharts-legend-marker {
  margin-right: 0 !important;
}

.apexcharts-legend-text {
  line-height: 12px !important;
  text-transform: uppercase;
  margin-top: 5px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;
}

.activeSettingsTab {
  position: relative;
  color: #3f7cdb !important;
  display: inline-block;
}

.activeSettingsTab::before {
  content: '';
  position: absolute;
  inset: 0;
  border-bottom: 1px solid #3f7cdb;
}

progress::-moz-progress-bar {
  background: #3f7cdb;
  border-radius: 50px;
}

progress::-webkit-progress-value {
  background: #3f7cdb;
}

progress {
  background-color: #eee;
  width: 100%;
  border-radius: 50px !important;
  height: 20px;
}

progress {
  background-color: #eee;
  border: 0;
  height: 18px;
  border-radius: 20px;
}

progress::-webkit-progress-bar {
  background-color: #eee;
  border: 0;
  height: 18px;
  border-radius: 20px;
}

progress::-webkit-progress-value {
  border: 0;
  height: 18px;
  border-radius: 20px;
}

progress::-moz-progress-bar {
  background-color: #eee;
  border: 0;
  height: 18px;
  border-radius: 20px;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 80vh;
}

.truncated-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.liveness_slider {
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.liveness_slider input[type='range'] {
  width: 100%;
  height: 2px;
  /* background-color: black; */
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
}

.print_aml .header,
.print_aml .header-space {
  height: 50px;
}

.print_aml .footer,
.print_aml .footer-space {
  height: 50px;
}

.print_aml .header {
  position: fixed;
  top: 0;
  left: 0;
}

.print_aml .footer {
  position: fixed;
  bottom: 0;
  left: 0;
}

@page {
  margin: 10mm;
}

.pre {
  padding: 10px 40px !important;
  font-size: 13px !important;
}

.highlighter pre {
  font-size: 13px;
  padding: 20px 10px !important;
  border-radius: 12px;
}

.add_project_select .react-select__control {
  height: 38px;
}
.custom-questions-select .css-13cymwt-control {
  height: 56px;
  border-radius: 8px;
  border: 1px solid #f9f9f9;
  cursor: pointer;
}
.custom-questions-select .css-13cymwt-control:hover {
  border: 1px solid #f9f9f9 !important;
}
.custom-questions-select .css-1u9des2-indicatorSeparator {
  display: none;
}
.custom-questions-select .css-t3ipsp-control {
  border: 1px solid #f9f9f9 !important;
  box-shadow: none !important;
  border-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #f9f9f9;
}
.custom-questions-select .css-1nmdiq5-menu {
  padding: 12px;
  background-color: #f0f3ff;
  border-radius: 8px;
}
.answer-option-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
.answer-option-content.open {
  max-height: 500px;
}

.add_project_select .react-select__placeholder {
  color: #959595 !important;
  font-weight: 400;
}
.add_project_select .react-select__indicator {
  padding: 10px !important;
}

.project_line_chart .apexcharts-xaxis-label {
  font-size: 10px;
  text-transform: uppercase;
}
.project_line_chart .apexcharts-legend {
  overflow: hidden;
}
.project_line_chart text:first-child {
  transform: translate(0, 0) !important;
}
.project_line_chart text:last-child {
  transform: translate(0, 0) !important;
}

.project_line_chart .apexcharts-yaxis-label {
  font-size: 12px;
  text-transform: uppercase;
}

.required:after {
  content: ' *';
  color: #ca2b2b;
}

/* width */
.listbox-scroll::-webkit-scrollbar {
  width: 4px;
  border-radius: 12px;
}

/* Track */
.listbox-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 12px;
}

/* Handle */
.listbox-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 12px;
}

/* Handle on hover */
.listbox-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 12px;
}


.spinner {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #3f7cdb;
  border-right-color: rgba(255, 255, 255, 0.3);
  position: relative;
  animation: spin 0.3s linear infinite ;
  -webkit-animation: spin 0.3s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
